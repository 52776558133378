import { createApp } from 'vue';
import { createMetaManager } from 'vue-meta';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import EmailVerification from './pages/EmailVerification.vue';
import LandingPage from './pages/LandingPage.vue';
import PasswordReset from './pages/PasswordReset.vue';

import PrivacyPolicyCH from './pages/PrivacyPolicyCH.vue';
import PrivacyPolicyEN from './pages/PrivacyPolicyEN.vue';
import PrivacyPolicyJP from './pages/PrivacyPolicyJP.vue';

import TermsAndConditions from './pages/TermsAndConditions.vue';


const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        {
            path: '/',
            component: LandingPage,
        },
        {
            path: '/privacy-policy',
            component: PrivacyPolicyEN,
        },
        {
            path: '/privacy-policy-ch',
            component: PrivacyPolicyCH,
        },
        {
            path: '/privacy-policy-jp',
            component: PrivacyPolicyJP,
        },
        {
            path: '/terms-and-conditions',
            component: TermsAndConditions,
        },
        {
            path: '/email-verification',
            component: EmailVerification,
        },
        {
            path: '/password-reset',
            component: PasswordReset,
        },
        // {
        //     path: '/.well-known/assetlink.json',
        //     beforeEnter() {
        //         window.location.href = '/.well-known/assetlink.json';
        //     },
        // },
        // // Add a route for apple-app-site-association
        // {
        //     path: '/.well-known/apple-app-site-association',
        //     beforeEnter() {
        //         window.location.href = '/.well-known/apple-app-site-association';
        //     },
        // },
    ],
});

const app = createApp(App);

app.use(router);
app.use(createMetaManager());

app.mount('#app');

<template>
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="text-center mt-4 mb-4">
                    <h1 class="display-4">プライバシーポリシー</h1>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">一般条件</h5>
                        <p>
                            アプリをダウンロードまたは使用することにより、これらの利用規約が自動的に適用されます。そのため、アプリを使用する前に注意深くお読みください。
                        </p>
                        <p>
                            アプリ、アプリの一部、または当社の商標をいかなる方法でもコピーまたは変更することはできません。
                        </p>
                        <p>
                            アプリのソースコードを抽出しようとすることは禁止されており、アプリを他の言語に翻訳したり、派生バージョンを作成したりすることもできません。
                        </p>
                        <p>
                            アプリ自体、およびそれに関連するすべての商標、著作権、データベース権、その他の知的財産権は、引き続きFlorian Zopfに帰属します。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">サービスの変更と料金</h5>
                        <p>
                            Florian Zopfは、アプリができる限り有用で効率的であることを保証することに努めています。
                        </p>
                        <p>
                            当社は、いつでも、いかなる理由でもアプリを変更したり、サービスに料金を課したりする権利を留保します。
                        </p>
                        <p>
                            アプリまたはそのサービスの料金を請求する場合は、お客様が何に対して支払いを行うのかを明確に説明いたします。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">データセキュリティとプライバシー</h5>
                        <p>
                            Qwik VBTアプリは、サービスを提供するために、お客様から提供された個人データを保存および処理します。
                        </p>
                        <p>
                            オンラインモード機能を提供するため、ユーザーデータをクラウドに保存しています。当社はお客様のデータのプライバシーとセキュリティを非常に重視しており、不正アクセス、開示、改ざん、破壊からデータを保護するための業界標準の対策を実施しています。
                        </p>
                        <p>
                            ただし、インターネットを介した電子的な保存や送信の方法は100%安全ではなく、お客様のデータの絶対的な安全性を保証することはできませんのでご了承ください。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">第三者サービス</h5>
                        <p>
                            本アプリは、独自の利用規約を定める第三者サービスを使用しています。
                        </p>
                        <p>
                            アプリが使用する第三者サービスプロバイダーの利用規約へのリンク
                        </p>
                        <ul>
                            <li><a href="https://policies.google.com/terms" target="_blank"
                                    rel="noopener noreferrer">Google Playサービス</a></li>
                        </ul>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">Googleサインイン</h5>
                        <p>
                            当アプリでは、お客様の利便性のためにGoogleでのサインインオプションを提供しています。このプロセスではメールアドレスへのアクセスが必要となりますが、これはアプリ内での一意の識別子としてのみ使用されます。当社はお客様のプライバシーを尊重し、メールアドレスを共有、販売、または他の目的で使用することは一切ありません。Googleでサインインを選択することにより、このメールアドレスの限定的な使用に同意したことになります。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">責任と使用</h5>
                        <p>
                            Wi-Fiにアクセスできない場合やデータ通信量が残っていない場合にアプリが完全に機能しないことについて、Florian Zopfは責任を負いかねます。
                        </p>
                        <p>
                            同様に、Florian Zopfはアプリの使用方法について常に責任を負うことはできません。例えば、デバイスの充電状態を維持することはお客様の責任となります。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">更新と終了</h5>
                        <p>
                            当社は、アプリを更新することがあります。Florian
                            Zopfは、お客様のデバイスにインストールされているAndroidおよびiOSバージョンに対して、常にアプリを関連性のある状態に保ち、動作するように更新することを約束するものではありません。
                        </p>
                        <p>
                            ただし、お客様には提供される更新を常に受け入れることを約束していただきます。また、当社はアプリの提供を停止することを希望する場合があり、終了の通知なしにいつでも使用を終了することができます。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">使用説明と最適な機能性</h5>
                        <p>
                            アプリの最適な機能性を確保するために、ユーザーがアプリ内で提供される指示に従うことは非常に重要です。
                        </p>
                        <p>
                            指示に従わない場合、不正確な結果が生じる可能性があり、アプリが意図したとおりに動作しない場合があります。Florian
                            Zopfは、ユーザーがアプリの指示に従わなかったことにより生じる問題や不正確さについて責任を負いかねます。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">利用規約の変更</h5>
                        <p>
                            当社は、アプリとサービスの変更を反映するため、随時利用規約を更新することがあります。
                        </p>
                        <p>
                            アプリの継続的な使用は、新しい利用規約への同意を意味します。定期的に利用規約を確認し、更新内容をご確認いただくことをお勧めします。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">お問い合わせ</h5>
                        <p>
                            利用規約に関するご質問やご提案がございましたら、お気軽にqwikvbt@gmail.comまでご連絡ください。
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<template>
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="text-center mt-4 mb-4">
                    <h1 class="display-4">隐私政策</h1>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">一般条款</h5>
                        <p>
                            通过下载或使用本应用程序，这些条款将自动适用于您 – 因此，在使用应用程序之前，您应该确保仔细阅读这些条款。
                        </p>
                        <p>
                            您不得以任何方式复制或修改应用程序、应用程序的任何部分或我们的商标。
                        </p>
                        <p>
                            您不得尝试提取应用程序的源代码，也不应尝试将应用程序翻译成其他语言或制作衍生版本。
                        </p>
                        <p>
                            应用程序本身以及与其相关的所有商标、版权、数据库权利和其他知识产权仍然属于Florian Zopf。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">服务变更和收费</h5>
                        <p>
                            Florian Zopf致力于确保应用程序尽可能实用和高效。
                        </p>
                        <p>
                            我们保留随时因任何原因对应用程序进行更改或对其服务收费的权利。
                        </p>
                        <p>
                            在向您收取应用程序或其服务的费用之前，我们会明确告知您具体支付内容。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">数据安全和隐私</h5>
                        <p>
                            Qwik VBT应用程序存储和处理您提供给我们的个人数据，以提供服务。
                        </p>
                        <p>
                            为了提供在线模式功能，我们在云端存储用户数据。我们非常重视您的数据隐私和安全，并已实施行业标准措施来保护您的数据免受未经授权的访问、披露、更改和破坏。
                        </p>
                        <p>
                            但是，请注意，通过互联网进行的电子存储或传输方式都不是100%安全的，我们无法保证您数据的绝对安全。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">第三方服务</h5>
                        <p>
                            本应用程序使用声明其自身条款和条件的第三方服务。
                        </p>
                        <p>
                            应用程序使用的第三方服务提供商的条款和条件链接
                        </p>
                        <ul>
                            <li><a href="https://policies.google.com/terms" target="_blank"
                                    rel="noopener noreferrer">Google Play服务</a></li>
                        </ul>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">Google登录</h5>
                        <p>
                            在我们的应用程序中，我们为了您的便利提供Google登录选项。此过程需要访问您的电子邮件地址，我们仅将其用作应用程序内的唯一标识符。我们尊重您的隐私：您的电子邮件永远不会被分享、出售或用于任何其他目的。选择使用Google登录即表示您同意对您的电子邮件地址进行这种有限使用。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">责任和使用</h5>
                        <p>
                            如果您无法访问Wi-Fi或数据流量已用完，导致应用程序无法完全正常运行，Florian Zopf不承担责任。
                        </p>
                        <p>
                            同样，Florian Zopf也不能始终对您使用应用程序的方式负责，即您需要确保您的设备保持充电状态。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">更新和终止</h5>
                        <p>
                            在某些时候，我们可能希望更新应用程序。Florian Zopf不承诺会始终更新应用程序以确保其与您设备上安装的Android和iOS版本相关和/或兼容。
                        </p>
                        <p>
                            但是，您承诺在收到更新提示时始终接受应用程序的更新。我们也可能希望停止提供应用程序，并可能随时终止其使用，而无需向您发出终止通知。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">使用说明和最佳功能</h5>
                        <p>
                            用户遵循应用程序内提供的说明对确保其最佳功能至关重要。
                        </p>
                        <p>
                            未能遵守说明可能导致不准确的结果，应用程序可能无法按预期运行。对于因用户未遵循应用程序说明而导致的任何问题或不准确性，Florian Zopf不承担责任。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">条款和条件的变更</h5>
                        <p>
                            我们可能会不时更新我们的条款和条件，以反映我们的应用程序和服务的变化。
                        </p>
                        <p>
                            继续使用应用程序即表示接受新的条款和条件。我们建议您定期查看条款和条件以了解任何更新。
                        </p>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <h5 class="card-title">联系我们</h5>
                        <p>
                            如果您对我的条款和条件有任何问题或建议，请随时通过qwikvbt@gmail.com与我联系。
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
